import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Button from "@amzn/meridian/button";
import Select, { SelectOption } from "@amzn/meridian/select";
import Box from "@amzn/meridian/box";
import Row from "@amzn/meridian/row";
import Loader from '@amzn/meridian/loader';
import { useBusinessRegionContext } from "../../auth/hooks";

export default function BusinessSelectionPage() {

  // list of Business options
  let businessList = [
    { label: "AFS", value: "AFS" },
    { label: "UFF", value: "UFF" },
  ]
  // list of Region options
  let regionList = [
    { label: "NA", value: 1 },
    { label: "EU", value: 2 },
  ]
  // list of Country options
  let countryList = [
    { label: "US", value: "US" },
    { label: "UK", value: "UK" },
  ]

  // Update states for selection
  // Default value will be the first element in list
  const [business, setBusiness] = useState(businessList[0].value);
  const [region, setRegion] = useState(regionList[0].value);
  const [country, setCountry] = useState(countryList[0].value);
  const [isLoading, setIsLoading] = useState(false);
  const {setBusinessRegionSelection} = useBusinessRegionContext();
  const history = useHistory();
  let userStorage = window.localStorage;
  const AUTH_WAIT = 300;

  // Listener to enter portal on click and update history
  function enterPortal() {
    userStorage.setItem('business', `${business}`);
    userStorage.setItem('region', `${region}`);
    userStorage.setItem('country', `${country}`);
    setBusinessRegionSelection(business, country, region);
    setIsLoading(true);

    setTimeout(() => { history.push(`/landing`); }, AUTH_WAIT);
  }

  //Business dropdown (will change size when selected, add animation later)
  function BusinessDropdown() {
    return (
      <Select placeholder="Business" value={business} onChange={setBusiness} width={300} label="Business">
        {
          businessList.map((option, i) => (
            <SelectOption value={option.value} key={i} label={option.label}></SelectOption>
          ))
        }
      </Select>
    );
  }

  // Region Dropdown
  function RegionDropdown() {
    return (
      <Select placeholder="Region" value={region} onChange={setRegion} width={300} label="Region">
        {
          regionList.map((option, i) => (
            <SelectOption value={option.value} key={i} label={option.label}></SelectOption>
          ))
        }
      </Select>
    );
  }

  // Country Dropdown
  function CountryDropdown() {
    return (
      <Select placeholder="Country" value={country} onChange={setCountry} width={300} label="Country">
        {
          countryList.map((option, i) => (
            <SelectOption value={option.value} key={i} label={option.label}></SelectOption>
          ))
        }
      </Select>
    );
  }

  // Submit button
  function SubmitButton() {
    if (business && country && region) {
      return <Button type="tertiary" onClick={() => enterPortal()}>
        Enter Portal
        {isLoading ? <Loader size="small" /> : null}
        </Button>
    }
    else {
      return <Button type="tertiary" disabled onClick={() => enterPortal()}>Enter Portal</Button>
    }
  }

  return (
    <>
      <Row alignmentHorizontal="center">
        <Box spacingInset="xlarge">
          <Box spacingInset="small">
            <BusinessDropdown></BusinessDropdown>
          </Box>
          <Box spacingInset="small">
            <RegionDropdown></RegionDropdown>
          </Box>
          <Box spacingInset="small">
            <CountryDropdown></CountryDropdown>
          </Box>
          <Box spacingInset="medium">
            <Row alignmentHorizontal="center">
              <SubmitButton></SubmitButton>
            </Row>
          </Box>
          
        </Box>
      </Row>
    </>
  );
}
