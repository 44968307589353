import {ApiGatewayErrorResponse} from '../apiGatewayClient';
import logger from '../../utils/logging';

export const extractStatusCode = (error: ApiGatewayErrorResponse): number | null => {
  return error.response?.status || null;
};

export const handleError = (error: ApiGatewayErrorResponse): void => {
  if (!error) { return; }
  const code = extractStatusCode(error);
  if (code === 401 || code === 403) {
    window.location.href = '/unauthorized';
  } else if (code === 500) {
    window.location.href = '/';
  }
  logger.error(error);
};
