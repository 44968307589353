import omit from 'lodash.omit';
import {ApiGatewayClient, ApiGatewayErrorResponse} from '../apiGatewayClient';
import {filterNullEmptyOrUndefinedKeys} from '../../utils/object';
import {GetForecastsListResponse} from '../models/getForecastsListResponse';
import {handleError} from './handleError';

export interface GetForecastsListParams {
  businessId: string;
  region: number;
  country: string;
  modelName: string;
  modelVersion: string;
  forecastCreationDate: string;
  siteId: string;
  asin: string;

  offset: number | undefined;
  pageSize: number | undefined;
  sortKey: string | undefined;
  sortDirection: string | undefined;
}

export const getForecastsListEndpoint = async (
  forecastsApiGatewayClient: ApiGatewayClient,
  params: GetForecastsListParams
): Promise<GetForecastsListResponse | void> => {
  try {
    const {businessId, region, country, modelName, modelVersion, forecastCreationDate, siteId, asin,
      offset, pageSize, sortKey, sortDirection} = params;

    const queryParams = filterNullEmptyOrUndefinedKeys({
      offset: offset || '',
      pageSize: pageSize || '500',
      sortKey: sortKey || '',
      sortDirection: sortDirection || ''
    });

    const headers = { 
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Origin': 'amazonaws.com',
      'Content-Type': 'application/json'
    };

    let response: GetForecastsListResponse;
    response =  (
      await forecastsApiGatewayClient.invokeApi(
        {businessId, region, country, modelName, modelVersion, forecastCreationDate, siteId, asin},
        '/business/{businessId}/region/{region}/country/{country}/modelName/{modelName}/modelVersion/{modelVersion}/' +
        'forecastCreationDate/{forecastCreationDate}/siteId/{siteId}/asin/{asin}/forecasts',
        'GET',
        {queryParams,
        headers},
        null
      )
    ).data;

    const utcOffsetInSeconds = new Date().getTimezoneOffset() * 60;

    response.forecasts.forEach(forecast => {
      forecast.forecastedDate = (forecast.forecastedDate + utcOffsetInSeconds) * 1000;
    });

    return response;
  } catch (error) {
    handleError(error as ApiGatewayErrorResponse);
  }
};
