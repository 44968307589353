import {AsinSumRecord} from '../apis/models/Actuals';
import {ERROR, IN_PROGRESS, SUCCESS} from '../constants/forecastStatus';
import {BASELINE, CLONED, DISCARDED, FINAL} from '../constants/forecastType';
import {formatPeriodDate} from './dates';

export const normalizeParameter = (value: string | undefined | null): string | undefined => {
  return !value || value === 'undefined' || value === 'null' ? undefined : value;
};

export const formatFriendlyWarehouseName = (actual: AsinSumRecord): string => {
  return actual.warehouse.toLocaleLowerCase();
};

/*
export const formatActualName = (actual: AsinSumRecord): string => {
  if (actual.businessId && actual.businessId === 'UFF')
    return `${actual.country} AFO ${formatPeriodDate(actual.period, actual.periodStart)}`;
  return `${actual.country} ${actual.businessId} ${formatPeriodDate(actual.period, forecast.periodStart)}`;
};

export const formatForecastDescription = (forecast: AsinSumRecord): string => {
  if (forecast.businessId && forecast.businessId === 'UFF')
    return forecast.description.replace('UFF ', 'AFO ');
  return forecast.description;
};*/

export const formatForecastStatus = (status: string, type: string): string => {
  if (type === DISCARDED) { return 'Discarded'; }
  switch (status) {
    case IN_PROGRESS:
      return 'Processing';
    case ERROR:
      return 'Error';
    case SUCCESS:
      switch (type) {
        case FINAL:
          return 'Final';
        case BASELINE:
        case CLONED:
        default:
          return 'Open';
      };
    default:
      return 'Unknown';
  };
};
