import "ka-table/style.scss";
import React from 'react';
import '../styles.scss';
import DatePicker from "@amzn/meridian/date-picker"
interface WorkflowDateProps {
  onChangeEvent: (e) => any
  setDate: string
  label: string
  width?: number
}


const WorkflowDate: React.FC<WorkflowDateProps> = (props: WorkflowDateProps) => {
  let { onChangeEvent, setDate, label , width} = props
  if (width === undefined) width = 200;
  return (
    <DatePicker value={setDate} onChange={onChangeEvent} width={width} label={label} />
  )
}

export default WorkflowDate