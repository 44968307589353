import {ForecastsRecord} from './ForecastsRecord';
export enum ForecastsListSortKeys {
  ForecastGroupId = 'forecast_group_id',
  Reason = 'reason',
  Quantile = 'quantile',
  ForecastValue = 'forecast_value',
  ForecastCreationDate = 'forecast_creation_date',
  ForecastedDate = 'forecasted_date'
}

export interface GetForecastsListResponse {
  forecasts: Array<ForecastsRecord>;
  pageSize: number;
  totalRecords: number;
}

