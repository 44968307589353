import React, { useState } from 'react';
import SearchField from "@amzn/meridian/search-field"
import { set } from 'date-fns';

interface WorkflowWarehouseFieldProps {
  warehouseDefaultState: string
  onSubmitEvent: (e) => any
}

const WorkflowWarehouseField: React.FC<WorkflowWarehouseFieldProps> = (props: WorkflowWarehouseFieldProps) => {
  const { warehouseDefaultState, onSubmitEvent } = props
  const [warehouse, setWarehouse] = useState(warehouseDefaultState);

  return (
    <SearchField
      value={warehouse}
      onChange={setWarehouse}
      placeholder="Enter Warehouse..."
      onSubmit={
        (value) => {
          if (value) onSubmitEvent(value);
        }
      }
      width={200}
      size="xlarge"
      label="Warehouse"
    />

  )
}

export default WorkflowWarehouseField