import "ka-table/style.scss";
import React, { useState, useEffect } from 'react';

import { ITableProps, kaReducer, Table } from 'ka-table';
import { ActionType, DataType, PagingPosition, SortDirection, SortingMode } from 'ka-table/enums';
import { DispatchFunc } from 'ka-table/types';
import { hideLoading, loadData, search, setSingleAction, showLoading, updateData, updatePageIndex, updatePagesCount } from 'ka-table/actionCreators';
import { Column } from 'ka-table/models';
import '../styles.scss';
import LandingChart from './LandingChart'
import { RouteComponentProps } from "react-router-dom";
import { AsinActualsListSortKeys, GetAsinActualsListResponse } from "../../apis/models/getAsinActualsListResponse";
import { ForecastsListSortKeys, GetForecastsListResponse } from "../../apis/models/getForecastsListResponse";
import { getISODate, MILLIS_IN_THIRTY_DAYS } from "../../utils/dates";
import LandingTableHeader from "./LandingTableHeader";
import { getAsinActualsList, getForecastsList } from "../../apis/api-client";

const DEFAULT_PAGE_SIZE = 10;
const ALLOWED_PAGE_SIZES = [10, 20, 50];

const getDefaultStartDate = () => {
  // return getISODate(new Date(Date.now())); ---CHANGE IT BACK LATER
  return getISODate(new Date('2023-07-20'));
};

const getDefaultEndDate = () => {
  return getISODate(new Date(Date.now() + MILLIS_IN_THIRTY_DAYS));
};

function getDateTimeParam(value: string): Date | undefined {
  return value ? new Date(value) : undefined;
}

const buildTable = (actualsList: GetAsinActualsListResponse | void): ITableProps => {
  let columns: Column[] = [
    {key: 'orderDay', title: 'Order Day', dataType: DataType.String, width: 50},
    {key: 'warehouse', title: 'Site', dataType: DataType.String, width: 50},
    {key: 'demand', title: 'Demand', dataType: DataType.Number, width: 40},
    {key: 'isPromotion', title: 'Is Promotion', dataType: DataType.Boolean, width: 40},
    {key: 'promotionType', title: 'Promotion Type', dataType: DataType.String, width: 40},
    {key: 'instockRaw', title: 'Instock Raw', dataType: DataType.Number, width: 40},
  ];

  const tablePropsInit: ITableProps = {
    columns,
    data: ((actualsList && actualsList.actuals) || []),
    rowKeyField: 'date',
    sortingMode: SortingMode.SingleRemote,
    singleAction: loadData(),
    loading: {enabled: true},
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      pageSizes: ALLOWED_PAGE_SIZES,
      position: PagingPosition.Bottom,
    },
  };

  return tablePropsInit;
};

interface IProps {
  history: RouteComponentProps['history'];
  actualsList: GetAsinActualsListResponse | void;
  setActualsList: React.Dispatch<React.SetStateAction<void | GetAsinActualsListResponse | undefined>>;
  forecastsList: GetForecastsListResponse | void;
  setForecastsList: React.Dispatch<React.SetStateAction<void | GetForecastsListResponse | undefined>>;
  businessId: string;
  country: string;
  region: number;
}

const LandingTable = ({history, actualsList, setActualsList, forecastsList, setForecastsList, businessId, country, region}: IProps) => {
  // list of ModelType options
  let modelValues = [
    { label: "LGBM", value: "lgbm" },
    { label: "BOSCO", value: "bosco" },
    { label: "Prospero", value: "Prospero" },
  ]
  let modelVersionValues = [
    { label: "lgbm-promo-lifts-v1", value: "lgbm-promo-lifts-v1" },
    { label: "cpp-v1", value: "cpp-v1" },
    { label: "prospero-v1", value: "prospero-v1" },
  ]
  const [tableProps, changeTableProps] = useState(buildTable(actualsList));
  const [startTime, setStartDateValue] = useState(getDefaultStartDate());
  const [endTime, setEndDateValue] = useState(getDefaultEndDate());
  const [forecastCreationDate, setforecastCreationDate] = useState("2024-04-30");
  const [percentile, setPercentile] = useState("90");
  const [asinInput, setAsinInput] = useState("B000Q61OZI"); // Defult Asin
  const [warehouseInput, setWarehouseInput] = useState("MAB1"); // Default Warehouse
  const [modelInput, setModelInput] = useState(modelValues[0].value);
  const [modelVersionInput, setModelVersionInput] = useState(modelVersionValues[0].value);

  const dispatch: DispatchFunc = async(action) => {
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
    if (action.type === ActionType.UpdateSortDirection) {
      dispatch(updatePageIndex(0));
    } else if (action.type === ActionType.UpdatePageIndex || action.type === ActionType.UpdatePageSize) {
      dispatch(setSingleAction(loadData()));
    } else if (action.type === ActionType.LoadData) {
      dispatch(showLoading());
      const sortCol = tableProps.columns.find((c) => c.sortDirection);
      const pageSize = tableProps?.paging?.pageSize || DEFAULT_PAGE_SIZE;
      const currentPageIndex = tableProps?.paging?.pageIndex || 0;
      const offset = currentPageIndex > 0 ? pageSize * currentPageIndex : undefined;
      let result : any = await getAsinActualsList({
        businessId,
        country,
        asin: asinInput,
        warehouse: warehouseInput,
        startTime: getDateTimeParam(startTime),
        endTime: getDateTimeParam(endTime),
        offset,
        pageSize,
        sortKey: sortCol ? sortCol.key : AsinActualsListSortKeys.OrderDay,
        sortDirection: sortCol ? sortCol.sortDirection : SortDirection.Descend,
      });

      // Format the Order Day as a DateString from unix time
      result?.actuals?.forEach((row) => {
        row.orderDay = new Date(row.orderDay).toDateString();
      });
      dispatch(updatePagesCount(result ? Math.ceil(result.totalRecords / pageSize) : 0));
      dispatch(updateData((result?.actuals || [])));
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getAsinActualsList({
      businessId,
      country,
      warehouse: warehouseInput,
      asin: asinInput,
      startTime: getDateTimeParam(startTime),
      endTime: getDateTimeParam(endTime),
      offset: undefined,
      pageSize: undefined,
      sortKey: AsinActualsListSortKeys.OrderDay,
      sortDirection: SortDirection.Descend,
    }).then((response) => {
      setActualsList(response);
      buildTable(response);
    });
  }, [businessId, country, warehouseInput, asinInput, startTime, endTime, setActualsList]);

  useEffect(() => {
    getForecastsList({
      businessId,
      region,
      country,
      modelName: modelInput,
      modelVersion: modelVersionInput,
      forecastCreationDate: forecastCreationDate,
      siteId: warehouseInput,
      asin: asinInput,
      offset: undefined,
      pageSize: undefined,
      sortKey: ForecastsListSortKeys.ForecastedDate,
      sortDirection: SortDirection.Descend,
    }).then((response) => {
      setForecastsList(response);
    });
  }, [businessId, country, warehouseInput, asinInput, modelInput, modelVersionInput, forecastCreationDate, startTime, endTime, setForecastsList]);

  useEffect(() => {
    changeTableProps(buildTable(actualsList));
  }, [actualsList, changeTableProps, startTime, endTime, percentile]);

    return (
      <div>
        <LandingTableHeader
          asinInput={asinInput}
          setAsinInput={setAsinInput}
          warehouseInput={warehouseInput}
          setWarehouseInput={setWarehouseInput}
          modelInput={modelInput}
          modelInputList={modelValues}
          setModelInput={setModelInput}
          modelVersionList={modelVersionValues}
          versionInput={modelVersionInput}
          setVersionInput={setModelVersionInput}
          startDate={startTime}
          setStartDateValue={setStartDateValue}
          endDate={endTime}
          setEndDateValue={setEndDateValue}
          forecastCreationDate={forecastCreationDate}
          setforecastCreationDate={setforecastCreationDate}
        />
        <LandingChart actualsList={actualsList} forecastsList={forecastsList}/>
        <Table
          {...tableProps}
          dispatch={dispatch}
          childComponents={{
            noDataRow: {
              content: () => 'No Data Found',
            },
          }}
        />
      </div>
    );
};

export default LandingTable;