import React, { version } from 'react';
import {css} from 'emotion';
import Select, {SelectOption} from "@amzn/meridian/select";
import Row from '@amzn/meridian/row';
import Box from "@amzn/meridian/box"
import WorkflowAsinField from '../inputFields/AsinField';
import WorkflowWarehouseField from '../inputFields/WarehouseField';
import WorkflowDate from '../inputFields/SearchDates';
import WorkflowDropdown from '../inputFields/SearchDropdowns';

const rowSpacing = css`
  padding-bottom: 0.5%;
`;

const smallMargin = css`
  margin-bottom: 4px !important;
`;

interface IProps {
  modelInputList: any;
  modelInput: string;
  setModelInput: React.Dispatch<React.SetStateAction<string>>;
  modelVersionList: any;
  versionInput: string;
  setVersionInput: React.Dispatch<React.SetStateAction<string>>;
  warehouseInput: string;
  setWarehouseInput: React.Dispatch<React.SetStateAction<string>>;
  asinInput: string;
  setAsinInput: React.Dispatch<React.SetStateAction<string>>;
  startDate: string;
  setStartDateValue: React.Dispatch<React.SetStateAction<string>>;
  endDate: string;
  setEndDateValue: React.Dispatch<React.SetStateAction<string>>;
  forecastCreationDate: string;
  setforecastCreationDate: React.Dispatch<React.SetStateAction<string>>;
}

const LandingTableHeader = (
    {
      modelInputList,
      modelInput,
      setModelInput,
      modelVersionList,
      versionInput,
      setVersionInput,
      warehouseInput,
      setWarehouseInput,
      asinInput,
      setAsinInput,
      startDate,
      setStartDateValue,
      endDate,
      setEndDateValue,
      forecastCreationDate,
      setforecastCreationDate,
    }: IProps) => {
  
  // Model Name Dropdown
  function ModelDropdown() {
    return (
      <Select placeholder="Model Name" value={modelInput} onChange={setModelInput} width={150} label="Model Name">
        {
          modelInputList.map((option, i) => (
            <SelectOption value={option.value} key={i} label={option.label}></SelectOption>
          ))
        }
      </Select>
    );
  }

  // Model Version Dropdown
  function ModelVersionDropdown() {
    return (
      <Select placeholder="Model Version" value={versionInput} onChange={setVersionInput} width={200} label="Model Version">
        {
          modelVersionList.map((option, i) => (
            <SelectOption value={option.value} key={i} label={option.label}></SelectOption>
          ))
        }
      </Select>
    );
  }

  return (
    <div>
      <Box spacingInset="xsmall">
        <Row alignmentVertical="center" alignmentHorizontal="center">
          <WorkflowWarehouseField warehouseDefaultState={warehouseInput} onSubmitEvent={setWarehouseInput}/>
          <WorkflowAsinField asinDefaultState={asinInput} onSubmitEvent={setAsinInput}/>
          <WorkflowDate onChangeEvent={setStartDateValue} setDate={startDate} label={"Actuals Start"} />
          <WorkflowDate onChangeEvent={setEndDateValue} setDate={endDate} label={"Actuals End"} />
        </Row>
        <Box spacingInset="xsmall">
          <Row alignmentHorizontal="center" alignmentVertical="center">
            <WorkflowDate onChangeEvent={setforecastCreationDate} setDate={forecastCreationDate} label={"Forecast Creation Date"} width={250} />
            <ModelDropdown/>
            <ModelVersionDropdown/>
          </Row>
        </Box>
      </Box>
    </div>
  )
};

export default LandingTableHeader;
