import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import LandingPage from '../features/landing-page/LandingPage';
import CommonHeader from './CommonHeader';
import InnerHeader from './InnerHeader';
import BusinessSelectionPage from './businessSelection/BusinessSelectionPage';
import HistoryPage from './history/HistoryPage';
import HelpPage from './help/HelpPage';
import DrillDownPage from './drilldown/DrillDownPage';
import React from 'react';
import QuicksightProspero from './quicksiteEmbed/QuicksiteEmbedProspero';
import QuicksightBosco from './quicksiteEmbed/QuicksiteEmbedBosco';
import UnauthorizedPage from '../features/unauthorized-page/UnauthorizedPage';
import ErrorPage from '../features/error-page/ErrorPage';
import { useAuthorizationContext, useBusinessRegionContext } from '../auth/hooks';

export default function App() {
  
  const {userSelection} = useBusinessRegionContext();
  const {isAuthorized, permissionsSet} = useAuthorizationContext();

  const RedirectToSelection = <Redirect to="/" />;
  const RedirectToUnauthorized = <Redirect to="/unauthorized" />;
  const redirect = (hasPermission: boolean, page: JSX.Element) => (isAuthorized && userSelection ? (hasPermission ? page: RedirectToUnauthorized) : RedirectToSelection);

  return (
      <BrowserRouter>
        <Switch>
          <Route path="/error">
            <ErrorPage />
          </Route>
          <Route path="/unauthorized" exact={true}>
            <UnauthorizedPage />
          </Route>

          <Route exact path="/">
            <InnerHeader />
            <BusinessSelectionPage></BusinessSelectionPage>
          </Route>

          <Route exact path="/landing" >
            <InnerHeader />
            <LandingPage></LandingPage>
          </Route>

          <Route path="/drilldown/:drilldown/:id">
            <InnerHeader />
            <DrillDownPage></DrillDownPage>
          </Route>

          <Route exact path="/help" >
            <InnerHeader />
            <HelpPage></HelpPage>
          </Route>

          <Route exact path="/history" >
            <InnerHeader />
            <HistoryPage></HistoryPage>
          </Route>

          <Route exact path="/quicksightProspero" >
            <InnerHeader />
            <QuicksightProspero></QuicksightProspero>
          </Route>

          <Route exact path="/quicksightBosco" >
            <InnerHeader />
            <QuicksightBosco></QuicksightBosco>
          </Route>
        </Switch>
      </BrowserRouter>
  );
}
