import { useState } from 'react';
import LandingTable from '../../components/landing/LandingTable';
import Box from "@amzn/meridian/box";
import { useHistory } from 'react-router-dom';
import { useBusinessRegionContext } from '../../auth/hooks';
import { GetAsinActualsListResponse } from '../../apis/models/getAsinActualsListResponse';
import { GetForecastsListResponse } from '../../apis/models/getForecastsListResponse';

// The content in the main content area of the App layout
export default function HistoryPage() {
  const history = useHistory();
  let userStorage = window.localStorage;
  const {business, country, region} = useBusinessRegionContext();
  const [actualsList, setActualsList] = useState<GetAsinActualsListResponse | void>();
  const [forecastsList, setForecastsList] = useState<GetForecastsListResponse | void>();


  return (
    <Box spacingInset="medium" maxHeight={200} minHeight={200}>
        <LandingTable
        history={history}
        actualsList={actualsList}
        setActualsList={setActualsList}
        forecastsList={forecastsList}
        setForecastsList={setForecastsList}
        businessId={business!}
        country={country!}
        region={region!}
        ></LandingTable>
    </Box>
  );
}
