import {AsinActualsRecord} from './AsinActuals';
export enum AsinActualsListSortKeys {
  BusinessId = 'businessid',
  Country = 'country',
  Warehouse = 'warehouse',
  Asin = 'asin',
  OrderDay = 'orderday',
  Demand = 'demand',
  InstockRaw = 'instock_raw',
  IsPromotion = 'is_promotion',
  PromotionType = 'promotion_type'
}

export interface GetAsinActualsListResponse {
  actuals: Array<AsinActualsRecord>;
  pageSize: number;
  totalRecords: number;
}
