import React from 'react';
import AuthenticationProvider from './AuthenticationProvider';
import AuthorizationProvider from './AuthorizationProvider';
import BusinessRegionProvider from './BusinessRegionProvider';

const CompositeProvider = ({children}: React.PropsWithChildren<Record<never, any>>) =>
    (
        <AuthenticationProvider>
            <BusinessRegionProvider>
                <AuthorizationProvider>
                    {children}
                </AuthorizationProvider>
            </BusinessRegionProvider>
        </AuthenticationProvider>

    );

export default CompositeProvider;
