export const percentileList = [
  { label: "P50", value: "50" },
  { label: "P70", value: "70" },
  { label: "P90", value: "90" },
]

export const drillDownList = [
  { label: "F3 Category", value: "f3Category" },
  { label: "Velocity Band", value: "velocityBand" },
  { label: "CIF Group", value: "cifGroup" },
]