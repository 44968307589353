import omit from 'lodash.omit';
import {ApiGatewayClient, ApiGatewayErrorResponse} from '../apiGatewayClient';
import {filterNullEmptyOrUndefinedKeys} from '../../utils/object';
import { GetAsinActualsListResponse } from '../models/getAsinActualsListResponse';
import {handleError} from './handleError';

export interface GetAsinActualsListParams {
  businessId: string;
  country: string;
  warehouse: string;
  asin: string;
  startTime: Date | undefined;
  endTime: Date | undefined;
  offset: number | undefined;
  pageSize: number | undefined;
  sortKey: string | undefined;
  sortDirection: string | undefined;
}

export const getAsinActualsListEndpoint = async (
  asinActualsApiGatewayClient: ApiGatewayClient,
  params: GetAsinActualsListParams
): Promise<GetAsinActualsListResponse | void> => {
  try {
    const {businessId, country, warehouse, asin, startTime, endTime} = params;
    const queryParams = filterNullEmptyOrUndefinedKeys({
      ...omit(params, ['businessId', 'country', 'startTime', 'endTime']),
      startTime: (startTime && Math.floor(startTime.getTime() / 1000)) || '',
      endTime: (endTime && Math.ceil(endTime.getTime() / 1000)) || '',
    });

    const headers = {
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Origin': 'amazonaws.com',
      'Content-Type': 'application/json'
    };

    let response: GetAsinActualsListResponse;
    
    response = (
      await asinActualsApiGatewayClient.invokeApi(
        {businessId, country, warehouse, asin},
        '/business/{businessId}/country/{country}/warehouse/{warehouse}/asin/{asin}/actuals',
        'GET',
        {queryParams,
        headers},
        null
      )
    ).data;

    const utcOffsetInSeconds = new Date().getTimezoneOffset() * 60;

    response.actuals.forEach(actual => {
      actual.orderDay = (actual.orderDay + utcOffsetInSeconds) * 1000;
    });

    return response
  } catch (error) {
    handleError(error as ApiGatewayErrorResponse);
  }
};
