import React, { useState } from 'react';
import SearchField from "@amzn/meridian/search-field"

interface WorkflowAsinFieldProps {
  asinDefaultState: string
  onSubmitEvent: (e) => any
}

const WorkflowAsinField: React.FC<WorkflowAsinFieldProps> = (props: WorkflowAsinFieldProps) => {
  const { asinDefaultState, onSubmitEvent } = props
  const [asin, setAsin] = useState(asinDefaultState);

  return (
    <SearchField
      value={asin}
      onChange={setAsin}
      placeholder="Enter Asin..."
      onSubmit={
        (value) => {
          if (value) onSubmitEvent(value);
        }
      }
      width={200}
      size="xlarge"
      label="Asin"
    />

  )
}

export default WorkflowAsinField