import {Credentials} from 'aws-sdk/clients/sts';
import {authTokenPayload} from '../../auth/AuthenticationProvider';
import {ApiGatewayClient, ApiGatewayErrorResponse} from '../apiGatewayClient';
import config, {SystemAPIs} from '../../config/config';
import {extractStatusCode, handleError} from './handleError';

import {getActualsAsinSumListEndpoint, GetActualsAsinSumListParams} from './getActualsAsinSumListEndpoint';
import {GetActualsAsinSumListResponse} from '../models/getActualsAsinSumListResponse';
import { GetForecastsAsinSumListResponse } from '../models/getForecastsAsinSumListResponse';
import { getForecastsAsinSumListEndpoint, GetForecastsAsinSumListParams } from './getForecastsAsinSumListEndpoint';
import { GetForecastsListResponse } from '../models/getForecastsListResponse';
import { getForecastsListEndpoint, GetForecastsListParams } from './getForecastsListEndpoint';
import { GetAsinActualsListResponse } from '../models/getAsinActualsListResponse';
import { getAsinActualsListEndpoint, GetAsinActualsListParams } from './getAsinActualsListEndpoint';

/* eslint-disable @typescript-eslint/no-var-requires */
const apiGatewayClientFactory = require('aws-api-gateway-client').default;

const initForecastingApiGatewayClient = async (): Promise<ApiGatewayClient> => {
  const tokenPayload = await authTokenPayload();

  const forecastingApiConfig = config.Amplify.API.endpoints.find((entry) => entry.name === SystemAPIs.ForecastingApi.toString());

  const forecastingApiCredentials = tokenPayload?.backendApiCredentials as Credentials;

  return apiGatewayClientFactory.newClient({
      invokeUrl: forecastingApiConfig!.endpoint,
      accessKey: forecastingApiCredentials!.AccessKeyId,
      secretKey: forecastingApiCredentials!.SecretAccessKey,
      sessionToken: forecastingApiCredentials!.SessionToken,
      region: forecastingApiConfig!.region,
  });
};


type EndpointFn<T, U> = (arg1: ApiGatewayClient, arg2: T) => Promise<U | void>;
async function execAndHandleError<T, U>(endpoint: EndpointFn<T, U>, params: T): Promise<U | void> {
  try {
    const client = await initForecastingApiGatewayClient();
    return endpoint(client, params);
  } catch (error) {
    const errorCode = extractStatusCode(error as ApiGatewayErrorResponse);
    if (errorCode === 404) {
      return;
    }
    handleError(error as ApiGatewayErrorResponse);
  }
}

export const getActualsAsinSumList = async (params: GetActualsAsinSumListParams): Promise<GetActualsAsinSumListResponse | void> => {
  return execAndHandleError(getActualsAsinSumListEndpoint, params);
};

export const getAsinActualsList = async (params: GetAsinActualsListParams): Promise<GetAsinActualsListResponse | void> => {
  return execAndHandleError(getAsinActualsListEndpoint, params);
};

export const getForecastsAsinSumList = async (params: GetForecastsAsinSumListParams): Promise<GetForecastsAsinSumListResponse | void> => {
  return execAndHandleError(getForecastsAsinSumListEndpoint, params);
};

export const getForecastsList = async (params: GetForecastsListParams): Promise<GetForecastsListResponse | void> => {
  return execAndHandleError(getForecastsListEndpoint, params);
};