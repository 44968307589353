import "../styles.scss"
import { Chart as ChartJS, registerables } from 'chart.js/auto';
import 'chartjs-adapter-dayjs-4'
import { Chart } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import { GetAsinActualsListResponse } from "../../apis/models/getAsinActualsListResponse";
import { GetForecastsListResponse} from "../../apis/models/getForecastsListResponse";

ChartJS.register(zoomPlugin);

interface IProps {
  actualsList: GetAsinActualsListResponse | void;
  forecastsList: GetForecastsListResponse | void;
}

export default function LandingChart({actualsList, forecastsList}: IProps) {
  type Coord = {'x': number, 'y': number};

  const actual_demand : Coord[] = [];
  const actual_instock_raw : Coord[] = [];
  const actual_is_promotion : Coord[] = [];
  if (actualsList) {
    actualsList.actuals.forEach(record => {
      actual_demand.push({
        x: record.orderDay,
        y: record.demand});
      actual_instock_raw.push({
        x: record.orderDay,
        y: record.instockRaw});
      actual_is_promotion.push({
        x: record.orderDay,
        y: Number(record.isPromotion)});
    });
  };

  // ForecastsList response contains all quantiles, so break up into different sets
  // for charting.
  const forecast_p90 : Coord[] = [];
  const forecast_p70 : Coord[] = [];
  const forecast_p60 : Coord[] = [];
  const forecast_p50 : Coord[] = [];
  if (forecastsList) {
    forecastsList.forecasts.forEach(record => {
      if (record.quantile == '90') {
        forecast_p90.push({
          x: record.forecastedDate,
          y: record.forecastValue});
      }
      if (record.quantile == '70') {
        forecast_p70.push({
          x: record.forecastedDate,
          y: record.forecastValue});
      }
      if (record.quantile == '60') {
        forecast_p60.push({
          x: record.forecastedDate,
          y: record.forecastValue});
      }
      if (record.quantile == '50') {
        forecast_p50.push({
          x: record.forecastedDate,
          y: record.forecastValue});
      }
    });
  };

  return (
    <>
      <div style={{ position: 'relative', marginInline: 'auto', height: 600, width: '80%' }}>
        <Chart type='bar' data={{
          datasets: [
                        {
              yAxisID: 'y_left',
              type: 'line',
              label: 'Demand',
              data: actual_demand,
              tension: 0.05,
            },
            {
              yAxisID: 'y_right',
              type: 'line',
              label: 'Instock Raw',
              data: actual_instock_raw,
              tension: 0.05,
            },
            {
              yAxisID: 'y_left',
              type: 'line',
              label: 'P90',
              data: forecast_p90,
              tension: 0.05,
            },
            {
              yAxisID: 'y_left',
              type: 'line',
              label: 'P70',
              data: forecast_p70,
              tension: 0.05,
              hidden: true
            },
            {
              yAxisID: 'y_left',
              type: 'line',
              label: 'P60',
              data: forecast_p60,
              tension: 0.05,
              hidden: true
            },
            {
              yAxisID: 'y_left',
              type: 'line',
              label: 'P50',
              data: forecast_p50,
              tension: 0.05,
            },
            {
              yAxisID: 'y_right',
              type: 'bar',
              label: 'Is Promotion',
              data: actual_is_promotion,
              categoryPercentage: 1.0,
              barPercentage: 1.0,
            }
          ]
        }}
        
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: { display: true, text: 'Date' },
              type: 'time',
              time: {
                minUnit: 'day'
              }
            },
            y_left: {
              title:  { display: true, text: 'Demand / Forecast Value' },
              type: 'linear',
              position: 'left',
            },
            y_right: {
              title: { display: true, text: 'Instock Raw Value'},
              type: 'linear',
              position: 'right',
              grid: {
                display: false,
              }
            }
          },
          plugins: {
            tooltip: {
              filter: function (tooltipItem) {
                return tooltipItem.datasetIndex !== 6;
              },
              intersect: false,
            },
            colors: {
              enabled: true
            },
            zoom: {
              pan: {
                enabled: true,
                mode: 'x',
              },
              zoom: {
                wheel: {
                  enabled: true,
                  speed: 0.05
                },
                pinch: {
                  enabled: true
                },
                mode: 'x',
              }
            }
          }
        }} />
      </div>
    </>
  );
}