import { Settings } from '../auth/AuthenticationProvider'

export enum SystemAPIs {
  ForecastingApi = 'ForecastingApi',
}

const settings = (await (await fetch(`${window.location.origin}/settings.json`)).json()) as Settings;

const config = {
  defaults: {
    namespace: 'Application',
  },
  Amplify: {
    Auth: {
      region: settings.region,
      identityPoolId: settings.cognitoIdentityPoolId,
      userPoolId: settings.cognitoUserPoolId,
      userPoolWebClientId: settings.cognitoAppClientId,
      mandatorySignIn: true,
      oauth: {
        domain: settings.cognitoAuthDomain,
        scope: ['openid', 'email', 'profile'],
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
        responseType: 'code',
      },
    },
    API: {
      endpoints: [
        {
          name: SystemAPIs.ForecastingApi.toString(),
          endpoint: settings.backendApiEndpoint,
          region: settings.region,
        },
      ],
    },
  },
};

export default config;
